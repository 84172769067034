module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","languages":["es","en","ca"],"defaultLanguage":"es","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6Z8JK7G8ED"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Padeleo App","short_name":"Padeleo","start_url":"/es/?user_mode=app","background_color":"#ffffff","theme_color":"#5ec7ac","display":"standalone","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"79f3e5c5aefdf2824c0a87035fe15c66"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
